<template>
  <div
    class="topic__item --new-notice"
    :class="[themeClass]"
    @click="topicPostsListLink(renderInfo)"
  >
    <template v-if="fieldsCount === 3">
      <!-- 非作者显示 -->
      <van-swipe-cell
        class="topic__item-swipe-cell"
        :data-id="renderInfo.id"
        :data-nohint="renderInfo.nohint ? 1 : 0"
        :before-close="onSwitchHint"
        v-if="!isGroup && role !== 'user'"
      >
        <div class="topic__item-swipe-cell-inner">
          <div class="item__option">
            <p v-if="renderInfo.stickCreatedAt">
              <svg-icon class="icon-stick" icon-class="icon-star"></svg-icon>
            </p>
            <p v-else>{{ queue + 1 - stickCounts }}</p>
          </div>
          <div class="item__option">
            <p>{{ renderInfo.title }}</p>
          </div>
          <div class="item__option">
            <svg-icon
              icon-class="share-star"
              class="reply-new-notice"
              v-if="!renderInfo.nohint && Number(renderInfo.noviewdCount) > 0"
            ></svg-icon>
            <p>
              <strong>{{
                renderInfo.unlockedPostsCount ? renderInfo.unlockedPostsCount : 0
              }}</strong
              >/<strong>{{ renderInfo.postsCount }}</strong>
            </p>
          </div>
        </div>
        <template #right v-if="role !== 'strange'">
          <van-button
            class="swipe-cell-button"
            :loading="requesting"
            :class="[renderInfo.nohint ? 'black' : 'red']"
          >
            {{ renderInfo.nohint ? "恢复提示" : "不再提示" }}
          </van-button>
        </template>
      </van-swipe-cell>
      <!-- 作者显示 -->
      <div class="topic__item-swipe-cell" v-else-if="!isGroup && role === 'user'">
        <div class="topic__item-swipe-cell-inner">
          <div class="item__option">
            <p v-if="renderInfo.stickCreatedAt">
              <svg-icon class="icon-stick" icon-class="icon-star"></svg-icon>
            </p>
            <p v-else>{{ queue + 1 - stickCounts }}</p>
          </div>
          <div class="item__option">
            <p>{{ renderInfo.title }}</p>
          </div>
          <div class="item__option">
            <svg-icon
              icon-class="share-star"
              class="reply-new-notice"
              v-if="!renderInfo.nohint && Number(renderInfo.noviewdCount) > 0"
            ></svg-icon>
            <p>
              <strong>{{
                renderInfo.unlockedPostsCount ? renderInfo.unlockedPostsCount : 0
              }}</strong
              >/<strong>{{ renderInfo.postsCount }}</strong>
            </p>
          </div>
        </div>
      </div>
      <!-- 小组显示 -->
      <van-swipe-cell
        class="topic__item-swipe-cell"
        :before-close="onHandleStick"
        :data-id="renderInfo.id"
        :data-stickat="renderInfo.stickCreatedAt"
        v-if="isGroup"
      >
        <div class="topic__item-swipe-cell-inner">
          <div class="item__option">
            <p v-if="renderInfo.stickCreatedAt">
              <svg-icon class="icon-stick" icon-class="icon-star"></svg-icon>
            </p>
            <p v-else>{{ queue + 1 - stickCounts }}</p>
          </div>
          <div class="item__option">
            <p>{{ renderInfo.title }}</p>
          </div>
          <div class="item__option">
            <svg-icon
              icon-class="share-star"
              class="reply-new-notice"
              v-if="!renderInfo.nohint && Number(renderInfo.noviewdCount) > 0"
            ></svg-icon>
            <p>
              <strong>{{
                renderInfo.unlockedPostsCount ? renderInfo.unlockedPostsCount : 0
              }}</strong
              >/<strong>{{ renderInfo.postsCount }}</strong>
            </p>
          </div>
        </div>
        <template #right v-if="role === 'user'">
          <van-button
            class="swipe-cell-button"
            :loading="requesting"
            :class="[renderInfo.stickCreatedAt ? 'black' : 'red']"
            >{{ renderInfo.stickCreatedAt ? "取消置顶" : "星标置顶" }}</van-button
          >
        </template>
      </van-swipe-cell>
    </template>
    <template v-else-if="fieldsCount === 4">
      <van-swipe-cell
        class="topic__item-swipe-cell"
        :before-close="onHandleStick"
        :data-id="renderInfo.id"
        :data-stickat="renderInfo.stickCreatedAt"
      >
        <div class="topic__item-swipe-cell-inner">
          <div class="item__option">
            <p v-if="renderInfo.stickCreatedAt">
              <svg-icon class="icon-stick" icon-class="icon-star"></svg-icon>
            </p>
            <p v-else>{{ queue + 1 - stickCounts }}</p>
          </div>
          <div class="item__option">
            <p>{{ renderInfo.title }}</p>
          </div>
          <div class="item__option">
            <p>{{ renderInfo.scope | formatScope }}</p>
          </div>
          <div class="item__option">
            <p>
              <strong>{{ renderInfo.postsCount }}</strong>
            </p>
          </div>
        </div>
        <template #right>
          <van-button
            class="swipe-cell-button"
            :loading="requesting"
            :class="[renderInfo.stickCreatedAt ? 'black' : 'red']"
            >{{ renderInfo.stickCreatedAt ? "取消置顶" : "星标置顶" }}</van-button
          >
        </template>
      </van-swipe-cell>
    </template>
  </div>
</template>

<script>
import { TOPIC_SCOPE_MAP } from '@/utils/dict';

export default {
  name: 'c__topic-item',
  props: {
    options: {
      type: Object,
    },
    fieldsCount: {
      type: Number,
    },
    themeClass: {
      type: String,
    },
    // role: {
    //   type: String,
    // },
    info: {
      type: Object,
    },
    queue: {
      type: Number,
    },
  },
  filters: {
    formatScope(val) {
      const temp = TOPIC_SCOPE_MAP.find((item) => Number(item.val) === Number(val));
      return temp.name;
    },
  },
  data() {
    return {
      requesting: false,
      renderInfo: this.info,
    };
  },
  computed: {
    stickCounts() {
      return this.$parent.$attrs.stickCounts;
    },
    role() {
      return this.$parent.$attrs.role;
    },
    isGroup() {
      return this.$parent.$attrs ? this.$parent.$attrs.extend === 'group' : false;
    },
  },
  mounted() {
    if (this.renderInfo.stickCreatedAt) {
      this.$parent.$emit('stickAdd');
    }
  },
  methods: {
    topicPostsListLink({ author_uid: authorUID, id: topicID, group_id: groupID }) {
      let link;
      if (this.isGroup) {
        link = `/group/${groupID}/topic/${topicID}`;
      } else {
        link = this.role === 'friend' ? `/friend/${authorUID}/topics/${topicID}` : `/topic/${topicID}`;
      }
      this.$router.push({
        path: link,
      });
    },
    onSwitchHint({ position, instance }) {
      const that = this;
      if (position === 'right') {
        that.requesting = true;
        const { id, nohint } = instance.$el.dataset;
        const url = Number(nohint) === 1
          ? `api/user/topic/hint-open/${id}`
          : `api/user/topic/hint-close/${id}`;
        that.$request
          .get(url)
          .then(() => {
            that.renderInfo.nohint = Number(nohint) === 1 ? null : Date.now();
          })
          .finally(() => {
            that.requesting = false;
            instance.close();
          });
      } else {
        instance.close();
      }
    },
    onHandleStick({ position, instance }) {
      const that = this;
      if (position === 'right') {
        that.requesting = true;
        const { id, stickat } = instance.$el.dataset;
        const isSticked = stickat !== undefined;
        const url = isSticked ? `api/user/topic/unstick/${id}` : `api/user/topic/stick/${id}`;
        that.$request
          .get(url)
          .then((res) => {
            const topicId = res.data;
            that.$parent.$emit('topicStickResult', {
              id: topicId,
              stick: !isSticked,
            });
          })
          .finally(() => {
            that.requesting = false;
            instance.close();
          });
      } else {
        instance.close();
      }
    },
  },
};
</script>

<style></style>
