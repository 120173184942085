<template>
  <div class="user-base-info-container">
    <div
      class="ubi__inner"
      :class="{
        'skeleton-loading-wrapper': loading,
      }"
    >
      <div class="ubi__friend-apply-status-container">
        <template v-if="role !== 'user'">
          <template v-if="info && info.applyStatus">
            <template v-if="Number(info.applyStatus.status) === inviteStatusMap.undo.val">
              <van-button
                v-if="
                  role === 'strange' &&
                  Number(info.applyStatus.relation) === inviteRelationMap.BEINVITED
                "
                class="apply__item waiting"
              >
                申请中
              </van-button>
              <van-button
                v-if="
                  role === 'strange' &&
                  Number(info.applyStatus.relation) === inviteRelationMap.INVITE
                "
                class="apply__item apply"
                :loading="requesting"
                :data-recordid="info.applyStatus.recordid"
                @click="onAccept"
              >
                通过
              </van-button>
            </template>
          </template>
          <template v-else>
            <template v-if="role === 'strange'">
              <van-button
                class="apply__item apply"
                @click="onFriendApply"
                :loading="requesting"
                v-if="!afterApplySuccess"
                >申请</van-button
              >
              <van-button v-else class="apply__item waiting"> 申请中 </van-button>
            </template>
            <van-button
              v-else-if="role === 'friend' && false"
              class="apply__item remove"
              :loading="requesting"
              @click="onFriendRemove"
              >解除</van-button
            >
            <router-link
              class="apply__item set-up"
              v-else-if="role === 'friend'"
              :to="{
                path: `/friend/${$route.params.friendUid}/setup`,
              }"
            >
              <svg-icon icon-class="icon-pen"></svg-icon>
            </router-link>
          </template>
        </template>
      </div>
      <div
        class="ubi__headerpic"
        :class="{
          'skeleton-item': loading,
        }"
        :data-src="info ? (role === 'user' ? info.headimgurl : info.info.headimgurl) : ''"
        :data-list="info ? [role === 'user' ? info.headimgurl : info.info.headimgurl] : []"
        v-preview
      >
        <img v-if="info" :src="role === 'user' ? info.headimgurl : info.info.headimgurl" alt="" />
      </div>
      <div class="ubi__info-text">
        <p
          class="info__username"
          :class="{
            'skeleton-item': loading,
          }"
        >
          <template>
            {{
              info
                ? role === "user"
                  ? info.nickname
                  : info.comment
                  ? info.comment
                  : info.info.nickname
                : ""
            }}
          </template>
        </p>
        <p
          class="info__nickname"
          :class="{
            'skeleton-item': loading,
          }"
        >
          <template v-if="role === 'friend' && info && info.comment">
            昵称：{{
              info && user && Number(user.uid) !== Number(info.info.uid) ? info.info.nickname : ""
            }}
          </template>
        </p>
        <p
          class="info__address"
          :class="{
            'skeleton-item': loading,
          }"
        >
          <template v-if="info">
            {{
              `${role === "user" ? info.province : info.info.province} ${
                role === "user" ? info.city : info.info.city
              }`
            }}
          </template>
        </p>
      </div>
      <div class="uib__action-container">
        <div
          class="action__bt --edit-nickname"
          v-if="info && user && role === 'friend' && Number(user.uid) !== Number(info.info.uid)"
        >
          <svg-icon icon-class="icon-pen"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { INVITE_STATUS_MAP, RELATION_STATUS_MAP } from '@/utils/dict';

export default {
  name: 'c_user-base-info',
  props: {
    info: {
      type: Object,
      default: null,
    },
    role: {
      /**
       * friend
       * strange
       * user
       */
      type: String,
    },
  },
  data() {
    return {
      requesting: false,
      inviteStatusMap: INVITE_STATUS_MAP,
      inviteRelationMap: RELATION_STATUS_MAP,
      afterApplySuccess: false,
    };
  },
  computed: {
    ...mapState(['user']),
    loading() {
      return this.info === null;
    },
  },
  methods: {
    onFriendApply() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get(`api/strange/friend-apply/${that.$route.params.friendUid}`)
          .then((res) => {
            if (res.data === 'SUCCESS') {
              that.$notify({
                type: 'success',
                message: '请求成功，请耐心等候',
              });
              that.afterApplySuccess = true;
            }
            that.requesting = false;
          })
          .catch(() => {
            that.requesting = false;
          });
      }
    },
    onFriendRemove() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$dialog
          .confirm({
            title: '提醒',
            message: '解除好友之后所有关联消失，\n是否确认要解除关系？',
          })
          .then(() => {
            that.$request
              .get(`api/user/friend/remove/${that.$route.params.friendUid}`)
              .then((res) => {
                if (res.data === 'SUCCESS') {
                  window.location.replace(window.location.href);
                }
                that.requesting = false;
              });
          })
          .catch(() => {
            that.requesting = false;
          });
      }
    },
    onAccept(e) {
      const that = this;
      const { recordid } = e.currentTarget.dataset;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get(`api/user/invite/accept/${recordid}`, {
            uid: that.$route.params.friendUid,
          })
          .then((res) => {
            if (res.data === 'SUCCESS') {
              window.location.replace(window.location.href);
            }
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
  },
};
</script>

<style></style>
