var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-base-info-container"},[_c('div',{staticClass:"ubi__inner",class:{
      'skeleton-loading-wrapper': _vm.loading,
    }},[_c('div',{staticClass:"ubi__friend-apply-status-container"},[(_vm.role !== 'user')?[(_vm.info && _vm.info.applyStatus)?[(Number(_vm.info.applyStatus.status) === _vm.inviteStatusMap.undo.val)?[(
                _vm.role === 'strange' &&
                Number(_vm.info.applyStatus.relation) === _vm.inviteRelationMap.BEINVITED
              )?_c('van-button',{staticClass:"apply__item waiting"},[_vm._v(" 申请中 ")]):_vm._e(),(
                _vm.role === 'strange' &&
                Number(_vm.info.applyStatus.relation) === _vm.inviteRelationMap.INVITE
              )?_c('van-button',{staticClass:"apply__item apply",attrs:{"loading":_vm.requesting,"data-recordid":_vm.info.applyStatus.recordid},on:{"click":_vm.onAccept}},[_vm._v(" 通过 ")]):_vm._e()]:_vm._e()]:[(_vm.role === 'strange')?[(!_vm.afterApplySuccess)?_c('van-button',{staticClass:"apply__item apply",attrs:{"loading":_vm.requesting},on:{"click":_vm.onFriendApply}},[_vm._v("申请")]):_c('van-button',{staticClass:"apply__item waiting"},[_vm._v(" 申请中 ")])]:(_vm.role === 'friend' && false)?_c('van-button',{staticClass:"apply__item remove",attrs:{"loading":_vm.requesting},on:{"click":_vm.onFriendRemove}},[_vm._v("解除")]):(_vm.role === 'friend')?_c('router-link',{staticClass:"apply__item set-up",attrs:{"to":{
              path: `/friend/${_vm.$route.params.friendUid}/setup`,
            }}},[_c('svg-icon',{attrs:{"icon-class":"icon-pen"}})],1):_vm._e()]]:_vm._e()],2),_c('div',{directives:[{name:"preview",rawName:"v-preview"}],staticClass:"ubi__headerpic",class:{
        'skeleton-item': _vm.loading,
      },attrs:{"data-src":_vm.info ? (_vm.role === 'user' ? _vm.info.headimgurl : _vm.info.info.headimgurl) : '',"data-list":_vm.info ? [_vm.role === 'user' ? _vm.info.headimgurl : _vm.info.info.headimgurl] : []}},[(_vm.info)?_c('img',{attrs:{"src":_vm.role === 'user' ? _vm.info.headimgurl : _vm.info.info.headimgurl,"alt":""}}):_vm._e()]),_c('div',{staticClass:"ubi__info-text"},[_c('p',{staticClass:"info__username",class:{
          'skeleton-item': _vm.loading,
        }},[[_vm._v(" "+_vm._s(_vm.info ? _vm.role === "user" ? _vm.info.nickname : _vm.info.comment ? _vm.info.comment : _vm.info.info.nickname : "")+" ")]],2),_c('p',{staticClass:"info__nickname",class:{
          'skeleton-item': _vm.loading,
        }},[(_vm.role === 'friend' && _vm.info && _vm.info.comment)?[_vm._v(" 昵称："+_vm._s(_vm.info && _vm.user && Number(_vm.user.uid) !== Number(_vm.info.info.uid) ? _vm.info.info.nickname : "")+" ")]:_vm._e()],2),_c('p',{staticClass:"info__address",class:{
          'skeleton-item': _vm.loading,
        }},[(_vm.info)?[_vm._v(" "+_vm._s(`${_vm.role === "user" ? _vm.info.province : _vm.info.info.province} ${ _vm.role === "user" ? _vm.info.city : _vm.info.info.city }`)+" ")]:_vm._e()],2)]),_c('div',{staticClass:"uib__action-container"},[(_vm.info && _vm.user && _vm.role === 'friend' && Number(_vm.user.uid) !== Number(_vm.info.info.uid))?_c('div',{staticClass:"action__bt --edit-nickname"},[_c('svg-icon',{attrs:{"icon-class":"icon-pen"}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }