const NOTICE_TYPE_GROUP_REPLY = 1;
const NOTICE_TYPE_GROUP_INVITE = 2;
const NOTICE_TYPE_GROUP_INFO_UPDATE = 3;
const NOTICE_TYPE_GROUP_DISSOLVE = 4;
const NOTICE_TYPE_GROUP_SET_ADMIN = 5;
const NOTICE_TYPE_GROUP_REMOVE_ADMIN = 6;
const NOTICE_TYPE_GROUP_HIDE_POST = 7;
const NOTICE_TYPE_GROUP_REMOVE_MEMBER = 8;
const NOTICE_TYPE_GROUP_APPLY_ACCEPT = 101;
const NOTICE_TYPE_GROUP_APPLY_REFUSE = 102;
const NOTICE_TYPE_GROUP_USER_APPLY = 111;
const NOTICE_TYPE_GROUP_USER_REMOVE = 112;

const NOTICE_TYPE_TIMELINE_REPLY = 11;
const NOTICE_TYPE_TIMELINE_POST = 12;

const NOTICE_TYPE_TOPIC_REPLY = 21;

const NOTICE_TYPE_FRIEND_INVITE = 31;
const NOTICE_TYPE_FRIEND_INVITE_PASSED = 32;

const NOTICE_TYPE_FEEDBACK_REPLY = 41;
const NOTICE_TYPE_FEEDBACK = 42;

const NOTICE_TYPE_SYS_WEEKLY_REPORT = 51;

const NOTICE_STATUS_UNREAD = 1;
const NOTICE_STATUS_HAS_READ = 2;

// 完全公开
const TOPIC_SCOPE_PUBLIC = 1;
// 部分可见
const TOPIC_SCOPE_PART_VISIBLE = 2;
// 部分不可见
const TOPIC_SCOPE_PART_INVISIBLE = 3;
// 私密
const TOPIC_SCOPE_PRIVATE = 4;

const TOPIC_SCOPE_MAP = [
  {
    val: TOPIC_SCOPE_PUBLIC,
    name: '公开',
  },
  {
    val: TOPIC_SCOPE_PART_VISIBLE,
    name: '部分可见',
  },
  {
    val: TOPIC_SCOPE_PART_INVISIBLE,
    name: '部分不可见',
  },
  {
    val: TOPIC_SCOPE_PRIVATE,
    name: '私密',
  },
];
const INVITE_STATUS_MAP = {
  undo: {
    val: 0,
    statusText: '未操作',
    inviterText: '申请中',
    beInvitedText: '通过',
  },
  done: {
    val: 1,
    statusText: '已操作',
    inviterText: '已通过',
    beInvitedText: '已通过',
  },
  expired: {
    val: 2,
    statusText: '超时',
    inviterText: '申请',
    beInvitedText: '已过期',
  },
};
const RELATION_STATUS_MAP = {
  INVITE: 1,
  BEINVITED: 2,
};

const logoutDefault = {
  nickname: '此用户已注销',
  headpic: 'http://timeline-file.crosscrew.cc/static/notice-default.png',
  headimgurl: 'http://timeline-file.crosscrew.cc/static/notice-default.png',
  shareimg: 'http://timeline-file.crosscrew.cc/static/notice-default.png',
  uid: 0,
};

const isSysNotice = (type) => [NOTICE_TYPE_SYS_WEEKLY_REPORT].includes(type);

export {
  RELATION_STATUS_MAP,
  INVITE_STATUS_MAP,
  NOTICE_TYPE_GROUP_REPLY,
  NOTICE_TYPE_GROUP_INVITE,
  NOTICE_TYPE_TIMELINE_REPLY,
  NOTICE_TYPE_TIMELINE_POST,
  NOTICE_TYPE_TOPIC_REPLY,
  NOTICE_TYPE_FRIEND_INVITE,
  NOTICE_STATUS_UNREAD,
  NOTICE_STATUS_HAS_READ,
  TOPIC_SCOPE_MAP,
  TOPIC_SCOPE_PUBLIC,
  TOPIC_SCOPE_PART_VISIBLE,
  TOPIC_SCOPE_PART_INVISIBLE,
  TOPIC_SCOPE_PRIVATE,
  NOTICE_TYPE_FRIEND_INVITE_PASSED,
  NOTICE_TYPE_GROUP_INFO_UPDATE,
  NOTICE_TYPE_GROUP_DISSOLVE,
  NOTICE_TYPE_GROUP_SET_ADMIN,
  NOTICE_TYPE_GROUP_REMOVE_ADMIN,
  NOTICE_TYPE_GROUP_HIDE_POST,
  NOTICE_TYPE_GROUP_REMOVE_MEMBER,
  NOTICE_TYPE_FEEDBACK_REPLY,
  NOTICE_TYPE_FEEDBACK,
  NOTICE_TYPE_GROUP_APPLY_ACCEPT,
  NOTICE_TYPE_GROUP_APPLY_REFUSE,
  NOTICE_TYPE_GROUP_USER_APPLY,
  NOTICE_TYPE_GROUP_USER_REMOVE,
  NOTICE_TYPE_SYS_WEEKLY_REPORT,
  logoutDefault,
  isSysNotice,
};
