<template>
  <div
    class="friend-detail-container"
    :class="{
      forbidden: forbidden,
    }"
  >
    <!-- 用户基本信息 -->
    <user-base-info :info="info" :role="role"></user-base-info>
    <!-- timeline 信息 -->
    <div class="timeline-info-container">
      <row-info-bar
        :title="role === 'user' ? '我的时光' : '关联时光'"
        :val="relatedTimelineCount"
        :link="timelineLink"
      ></row-info-bar>
    </div>
    <!-- topic列表 -->
    <div class="friends-detail-topic-list-container">
      <topic-list :header-nav="topicHeaderNav" :src="topicListRequestUrl" :role="role"></topic-list>
    </div>
    <slogan text="没有人不是宝藏"></slogan>
    <!-- 添加按钮 -->
    <add-bt icon="icon-plus-red" theme-color="red" @click.native="onCreateTimeline"></add-bt>
    <!-- 底部导航 -->
    <share-bottom></share-bottom>
    <!-- <div class="bottom-menu-container" v-else>
      <div class="menu__item" @click="goBack"><strong>返回</strong></div>
      <div class="menu__item" v-if="false && role === 'friend'">
        <svg-icon icon-class="icon-wx"></svg-icon>
        <strong>微信</strong>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import wxtool from '@/utils/wx-tool';
import userBaseInfo from '../user/_base-info.vue';
import rowInfoBar from '../common/_row-info-bar.vue';
import topicList from '../topic/_list.vue';
import slogan from '../common/_slogan.vue';
import addBt from '../common/_add-bt.vue';
import shareBottom from '../common/_share-bottom.vue';

export default {
  name: 'friend-detail',
  props: ['friendUid'],
  components: {
    userBaseInfo,
    rowInfoBar,
    topicList,
    slogan,
    addBt,
    shareBottom,
  },
  data() {
    return {
      topicHeaderNav: [
        {
          name: '排行',
        },
        {
          name: '主题',
        },
        {
          name: '我回应/分享',
        },
      ],
      relatedTimelineCount: 0,
      requesting: false,
      info: null,
      forbidden: false,
    };
  },
  computed: {
    ...mapState(['friends', 'user']),
    timelineLink() {
      return this.friendUid > 0 ? `/friend/${this.friendUid}/timeline` : '/my/timeline?type=mine';
    },
    topicListRequestUrl() {
      return this.friendUid > 0 ? `/api/user/${this.friendUid}/topic/list` : '/api/user/topic/list';
    },
    role() {
      if (this.friendUid > 0) {
        if (this.friends) {
          return this.friends.findIndex(
            (item) => Number(item.info.uid) === Number(this.friendUid),
          ) >= 0
            ? 'friend'
            : 'strange';
        }
        return 'strange';
      }
      return 'user';
    },
    queryFrom() {
      return this.$route.query.from || '';
    },
    isFromWX() {
      return this.queryFrom === 'wechat';
    },
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      immediate: true,
    },
    info(val) {
      const user = val.info;
      if (user && this.user) {
        const text = {
          title: `${this.user.nickname} 为您引荐好友 ${user.nickname}`,
          desc: '建设你 建设我 建设我们共同的时光',
          link: `${process.env.VUE_APP_BASE}friend/${user.uid}`,
          imgUrl: user.headimgurl,
        };
        wxtool.share(text, this.$route.fullPath);
      }
    },
  },
  methods: {
    ...mapActions(['userinfo']),
    init() {
      const that = this;
      that.requesting = false;
      that.info = null;
      that.userinfo().then((user) => {
        if (that.friendUid > 0) {
          that.getFriendInfo();
        } else {
          that.info = user;
          that.$request.get('api/user/account/timeline-count').then((res) => {
            that.relatedTimelineCount = res.data.count;
          });
        }
      });
    },
    getFriendInfo() {
      const that = this;
      if (!that.requesting) {
        const url = that.role === 'strange'
          ? `api/strange/${that.friendUid}`
          : `api/user/friend/${that.friendUid}`;
        that.$request
          .get(url)
          .then((res) => {
            const { data } = res;
            that.info = that.role === 'strange' ? data.strange : data.friend;
            that.relatedTimelineCount = data.relatedTimelineCount;
          })
          .catch((err) => {
            // 直接毛玻璃
            that.forbidden = true;
            console.log(err);
            const errMsg = err.status === 404 ? err.message : '你们还未成为好友';
            that
              .$dialog({
                message: errMsg,
              })
              .then(() => {
                that.$router.back(-1);
              });
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onCreateTimeline() {
      const path = this.role === 'friend' ? `/timeline/edit?friend=${this.friendUid}` : '/timeline/edit';
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style></style>
