<template>
  <div class="row-info-bar-container" :class="isOffset ? '--offset' : ''">
    <template v-if="link">
      <router-link
        class="bar__inner"
        :to="{
          path: link
        }"
      >
        <div class="bar__title">{{ title }}</div>
        <div class="bar__val">
          <p>
            <strong>{{ val }}</strong>
          </p>
          <svg-icon icon-class="icon-right"></svg-icon>
        </div>
      </router-link>
    </template>
    <template v-else>
      <div class="bar__inner">
        <div class="bar__title">{{ title }}</div>
        <div class="bar__val">
          <strong>{{ val }}</strong>
          <svg-icon v-if="icon" icon-class="icon-right"></svg-icon>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'c__row-info-bar',
  props: {
    title: {
      type: String,
      default: '',
    },
    val: {
      type: [String, Number],
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    isOffset: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
