<template>
  <div class="topic-list-container">
    <div class="tlc__header-container" :class="[themeClass]">
      <div class="header__item" v-for="(header, index) in headerNav" :key="index">
        {{ header.name }}
      </div>
    </div>
    <div class="tlc__inner" :class="[themeClass]">
      <van-list v-model="requesting" :finished="finished" :stickCounts="stickCounts" v-bind="$attrs" @load="onLoad"
        @stickAdd="onStickAdd" @topicStickResult="onTopicStick">
        <item v-for="(item, index) in lists" :key="item.id" :queue="index" :fields-count="fieldsCount"
          :theme-class="themeClass" :info="item"></item>
      </van-list>
    </div>
  </div>
</template>

<script>
import item from './_item.vue';

const THEME_CLASS = {
  3: 'three',
  4: 'four',
};

export default {
  name: 'c__topic-list',
  components: {
    item,
  },
  props: {
    headerNav: {
      type: Array,
      default: () => [],
      required: true,
    },
    src: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      fieldsCount: this.headerNav.length,
      lists: [],
      page: 1,
      totalPage: 1,
      requesting: false,
      finished: false,
    };
  },
  computed: {
    themeClass() {
      return `--${THEME_CLASS[this.fieldsCount]}`;
    },
    stickCounts() {
      const stick = this.lists.filter((i) => Number(i.stickCreatedAt) > 0);
      return stick.length;
    },
  },
  watch: {
    finished(val) {
      if (val) {
        this.$emit('finished', this.lists.length);
      }
    },
  },
  methods: {
    onLoad() {
      const that = this;
      if (that.src) {
        that.$request
          .get(that.src, {
            page: that.page,
          })
          .then((res) => {
            const { data } = res;
            const { list } = data;
            const meta = data._meta;
            that.lists.push(...list);
            if (that.page >= meta.pageCount) {
              that.finished = true;
            }
            that.page += 1;
          })
          .catch((err) => {
            console.log(err);
            that.finished = true;
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onStickAdd() {
      // this.stickCounts += 1;
    },
    onTopicStick(val) {
      const { id, stick } = val;
      const index = this.lists.findIndex((list) => Number(list.id) === Number(id));
      this.$set(this.lists[index], 'stickCreatedAt', stick ? Math.floor(Date.now() / 1000) : null);
      if (stick) {
        // 置顶
        this.lists.unshift(this.lists[index]);
        this.lists.splice(index + 1, 1);
      }
    },
  },
};
</script>

<style></style>
